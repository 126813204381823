nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1b2430;
    padding: 20px 80px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  }
  #navbar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #navbar li {
    list-style: none;
    padding: 0 20px;
    position: relative;
  }
  
  .link {
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    transition: 0.3s ease-in-out;
  }
  .link:hover,
  .active {
    color: #17cf97;
  }
  
  .link:hover::after,
.active::after {
    content: "";
    width: 30%;
    height: 2px;
    background: #17cf97;
    position: absolute;
    bottom: -4px;
    left: 20px;
  }
  /* dropDown */
  .dropdown {
    position: relative;
    display: inline-block;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    transition: 0.3s ease-in-out;
    
  }
  
  
 
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  #navbar .dropDown{
    display: block;
  }
  #mobile {
    display: none;
  }
  
  #mobile i {
    color: #fff;
    align-items: center;
  }
  .link-DropDown{
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: #2b2a2a;
    transition: 0.3s ease-in-out;
  }
  
 